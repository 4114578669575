<template>
  <div class="px-1">
    <b-row>
      <!-- {{ employee.employee.bank_info }} -->
      <b-col class="shadow-lg rounded-lg">
        <p class="py-1 step-text">
          Profile Completed
          <span class="step-percentage">{{ percentage }} %</span>
        </p>
        <b-row v-if="!hasSeenCongrats" class="p-2 register-stepper">
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 1, 'step-done': step > 1 }"
            @click.prevent="switchStep(1)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-basic-information.png"
                height="80px"
                width="80px"
                alt=""
                class="mb-2"
              />
              <p class="">Basic Information</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 2, 'step-done': step > 2 }"
            @click.prevent="switchStep(2)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-next-to-keen.png"
                height="80px"
                width="80px"
                alt=""
                class="mb-2"
              />
              <p class="">Next To Kin</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 3, 'step-done': step > 3 }"
            @click.prevent="switchStep(3)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-necessary-docs.png"
                alt=""
                height="80px"
                width="80px"
                class="mb-2"
              />
              <p class="">Necessary Docs.</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 4, 'step-done': step > 4 }"
            @click.prevent="switchStep(4)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-bank-information.png"
                height="80px"
                width="80px"
                alt=""
                class="mb-2"
              />
              <p class="">Bank Information</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 5, 'step-done': step > 5 }"
            @click.prevent="switchStep(5)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-skill-test.png"
                height="80px"
                width="80px"
                class="mb-2"
                alt=""
              />
              <p class="">Skill Test</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 6, 'step-done': step > 6 }"
            @click.prevent="switchStep(6)"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-take-photo.png"
                height="80px"
                width="80px"
                class="mb-2"
                alt=""
              />
              <p class="">Upload Photo</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 7, 'step-done': step > 7 }"
            @click.prevent="switchStep(7)"
            v-if="$can('change password', 'Employee')"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-change-password.png"
                height="80px"
                width="80px"
                class="mb-2"
                alt=""
              />
              <p class="">Change Password</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-show="step === 1">
        <basic-information
          v-if="employee.basic_info"
          :availability="availability"
          :basic_info="employee.basic_info"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 2">
        <next-to-keen
          v-if="employee.employee && employee.employee.keen"
          :keen="employee.employee.keen"
          :id="employee.employee.id"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 3">
        <necessary-docs
          v-if="employee.employee && employee.employee.passport"
          :passport="employee.employee.passport"
          :visaInfo="employee.employee.visa"
          :residentType="employee.employee.resident_type"
          :driving="employee.employee.driving"
          :certificate="employee.employee.certificate"
          :tax="employee.employee.tax"
          :id="employee.employee.id"
          @next="next"
          @getEmployee="getEmployee"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 4">
        <bank-information
          v-if="employee.employee && employee.employee.bank_info"
          :bank_info="employee.employee.bank_info"
          :id="employee.employee.id"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 5">
        <skill-test @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 6">
        <take-photo
          v-if="employee.employee"
          :employee="employee"
          :id="employee.employee.id"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row v-if="$can('change password', 'Employee')">
      <b-col v-show="step === 7">
        <change-password
          v-if="employee.employee"
          :id="employee.employee.id"
          @next="next"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
import BankInformation from "./components/bank-information.vue";
import BasicInformation from "./components/basic-information.vue";
import ChangePassword from "./components/change-password.vue";
import NecessaryDocs from "./components/necessary-docs.vue";
import NextToKeen from "./components/next-to-keen.vue";
import SkillTest from "./components/skill-test.vue";
import TakePhoto from "./components/take-photo.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BasicInformation,
    NextToKeen,
    NecessaryDocs,
    BankInformation,
    SkillTest,
    TakePhoto,
    ChangePassword,
  },
  data() {
    return {
      step: 1,
      hasSeenCongrats: false,
      employee: {},
      percentage: 0,
      availability: [],
    };
  },
  mounted() {
    const Id = this.$route.params.id;
    this.getEmployee(Id);
  },
  computed: {

  },
  methods: {
    prev() {
      if (this.step > 0) {
        this.step--;
      }
    },

    next() {
      if (this.step < 7) {
        this.step++;
      }
    },
    switchStep(step) {
      this.step = step;
    },

    customerRegister() {
      this.hasSeenCongrats = true;
    },

    getEmployee(id) {
      this.$store.dispatch("employee/getEmployee", id).then((res) => {
        this.employee = res.data;
        this.availability = this.employee.availability;
        this.employee.basic_info = {
          id: this.employee.id,
          avatar: this.employee.avatar,
          first_name: this.employee.first_name,
          last_name: this.employee.last_name,
          birth_date: this.employee.employee.birth_date,
          nationality: this.employee.employee.country_id,
          address: this.employee.employee.address,
          zip_code: this.employee.employee.zip_code,
          position: this.employee.employee.position_id,
          city: this.employee.employee.city,
          region: this.employee.employee.region,
          payment_per_hour: this.employee.employee.payment_per_hour,
          employment_type: this.employee.employee.employment_type_id,
          sector: this.employee.employee.sector_id,
          phone: this.employee.phone,
          email: this.employee.email,
          gender: this.employee.employee.gender,
          residential_address: this.employee.employee.residential_address,
          postal_address: this.employee.employee.postal_address,
          resident_type: this.employee.employee.resident_type,
          is_postal_same_as_residential:
            this.employee.employee.is_postal_same_as_residential,
        };

        if (
          this.employee.basic_info?.email != "" &&
          this.employee.basic_info?.name != "" &&
          this.employee.basic_info?.phone != ""
        ) {
          this.percentage = 20;
        }

        if (!this.employee.employee.keen) {
          this.employee.employee.keen = {
            id: this.employee.employee.id,
            employee_id: this.employee.employee.id,
            address: "",
            relation: "",
            phone: "",
            name: "",
            email: "",
          };
        }
        if (!this.employee.employee.tax) {
          this.employee.employee.tax = {
            id: this.employee.employee.id,
            employee_id: this.employee.employee.id,
            free_tax_threshold: "",
            resident_status: "",
            tax_scale_type: "",
            tfn_exemption: "",
            tfn_number: "",
          };
        }
        if (!this.employee.employee.visa) {
          this.employee.employee.visa = {
            id: this.employee.employee.id,
            resident_type: this.employee.employee.resident_type,
            expiry_date: "",
            visa_grant_number: "",
            visa_type: "",
            sub_class: "",
            employee_id: "",
          };
        }

        if (
          this.employee.employee.keen?.id != "" &&
          this.employee.employee.keen?.name != "" &&
          this.employee.employee.keen?.relation != ""
        ) {
          this.percentage = 40;
        }
        if (!this.employee.employee.passport) {
          this.employee.employee.passport = {
            id: this.employee.employee.id,
            passport_issue_country: "",
            passport_number: "",
            passport_validation: "",
            passport_file: "",
            status: "",
          };
        }
        // if (!this.employee.employee.visa) {
        //   this.employee.employee.visa = {
        //     id: this.employee.employee.id,
        //     expiry_date: "",
        //     resident_type: "",
        //     sub_class: "",
        //     visa_card_number: "",
        //     visa_grant_number: "",
        //     visa_type: "",
        //   };
        // }
        if (this.employee.employee.passport?.passport_number != "") {
          this.percentage = 50;
        }
        if (!this.employee.employee.driving) {
          this.employee.employee.driving = {
            id: this.employee.employee.id,
            issuing_state: "",
            license_file: "",
            license_number: "",
            license_validation: "",
            status: "",
          };
        }

        if (this.employee.employee.driving?.license_number != "") {
          this.percentage = 60;
        }

        if (!this.employee.employee.bank_info) {
          this.employee.employee.bank_info = {
            id: this.employee.employee.id,
            bank_name: "",
            address: "",
            personal_bsb_number: "",
            personal_account_number: "",
            super_name: "",
            super_bsb_number: "",
            super_account_number: "",
            super_abn: "",
            super_phone_number: "",
            account_type: "",
          };
        }
        if (this.employee.employee.bank_info?.personal_account_number != "") {
          this.percentage = 70;
        }
        if (this.employee.employee.certificate.length > 0) {
          this.percentage = 100;
        }
        if (this.employee.employee.certificate.length < 1) {
          this.employee.employee.certificate.push({
            certificate_file: "",
            certificate_name: "",
            certificate_number: "",
            certificate_validation: "",
            certificate_issuing_date: "",
            certificate_number_state: null,
            certificate_name_state: null,
            certificate_validation_state: null,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.step-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 53px;

  /* Text Additional */

  color: #79869f;
}

.step-percentage {
  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* ===========================================================
    step area css
   =========================================================== */
/* VARIABLES */
/* COLORS */
/* FONT */

.register-stepper .step {
  /* 20 medium */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;

  /* Text 2 */
  color: #a8adaf;
}

.register-stepper .step-active {
  /* background-color: #fff;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #01c839, #016667) border-box;
  border: 2px solid transparent; */
  color: #7190ef;
  /* 20 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
}
</style>
